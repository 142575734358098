import React, {useState} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {cancelAtPeriodEnd} from '../../../data/api';

/**
 * @typedef {object} CancelAtPeriodEndWarningProps
 * @property {boolean} willCancelAtPeriodEnd
 * @property {string} currentPeriodEnd
 * @property {function} closeAndReload
 * @property {string} subscriptionId
 */

/**
 * CancelAtPeriodEndWarning Component
 * @param {CancelAtPeriodEndWarningProps} props
 * @returns {JSX.Element}
 */
function CancelAtPeriodEndWarning({
    willCancelAtPeriodEnd,
    currentPeriodEnd,
    closeAndReload,
    subscriptionId
}) {
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    // format the date into a 'November 28, 2024' format
    const formattedDate = currentPeriodEnd
        ? new Date(currentPeriodEnd).toLocaleDateString('en-US', {
            month: 'long',
            day: 'numeric',
            year: 'numeric'
        })
        : null;

    // depending on if we have a cancellation date on hand
    // we render a different text
    const cancelSummary = formattedDate
        ? (
            <>
                Your site will go offline on{' '}
                <strong data-testid="cancel-summary-date">{formattedDate}</strong>
            </>
        )
        : (
            <>Your site will go offline at the end of the billing period</>
        );

    // Return null if the subscription is not set to cancel at
    // the end of the period, so we don't have to create a conditional
    // in the parent component
    if (!willCancelAtPeriodEnd) {
        return null;
    }

    const handleContinueSubscription = async () => {
        setError(null);
        // local loading state, which is used to disable the button
        setIsLoading(true);

        try {
            const res = await cancelAtPeriodEnd({
                subscriptionId,
                continue: true
            });

            setIsLoading(false);

            if (res?.error) {
                const loggingError = new Error('Failed to reactivate subscription');

                Sentry.captureException(loggingError, {
                    tags: {
                        source: 'cancel-at-period-end',
                        shown_to_user: true
                    },
                    contexts: {
                        full_error: res?.error?.originalError || res?.error
                    },
                    extra: {
                        subscriptionId
                    }
                });

                setError('Something went wrong. Refresh the page and try again or contact support@ghost.org');

                return;
            }

            // controls the parent component loading state
            closeAndReload({reload: true, delay: 0});
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    source: 'cancel-at-period-end',
                    shown_to_user: true
                },
                extra: {
                    subscriptionId
                }
            });

            setError('Something went wrong. Refresh the page and try again or contact support@ghost.org');
            setIsLoading(false);
        }
    };

    return (
        <div className="cancel-warning">
            <div className="cancel-warning-content">
                <div>
                    <p className="red">You've canceled your subscription</p>
                    <div className="cancel-summary">
                        <p data-testid="cancel-summary">
                            {cancelSummary}
                        </p>
                        <div
                            className={`reactivate-link-wrapper ${isLoading ? 'disabled' : ''}`}
                            data-testid="reactivate-button"
                        >
                            <span>(</span>
                            <button
                                className="reactivate-link"
                                onClick={isLoading ? null : handleContinueSubscription}
                            >
                                Reactivate
                            </button>
                            <span>)</span>
                        </div>
                    </div>
                </div>
            </div>
            {error &&
                <p className="error-msg">{error}</p>
            }
        </div>
    );
}

CancelAtPeriodEndWarning.propTypes = {
    currentPeriodEnd: PropTypes.string,
    willCancelAtPeriodEnd: PropTypes.bool,
    subscriptionId: PropTypes.string,
    closeAndReload: PropTypes.func
};

export default CancelAtPeriodEndWarning;
