import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import SpinnerButton from '../shared/SpinnerButton';
import useToggleAutoBilling from '../../hooks/useToggleAutoBilling';

export default function BillingMode({
    onCloseModal,
    user,
    subscription,
    autoPriceId,
    updateBillingModeText,
    autoBillingAvailable,
    setModalLoadingState,
    autoBillingEnabled,
    setAutoBillingEnabled,
    willCancelAtPeriodEnd,
    isGrace
}) {
    const buttonStates = {
        success: {
            className: 'gh-btn gh-btn-block',
            html: '&#10004; Update complete!',
            type: 'submit',
            disabled: true
        },
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            type: 'submit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div></span>',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Update',
            type: 'submit',
            disabled: false
        }
    };

    const [selectedMode, setSelectedMode] = useState(autoBillingEnabled ? 'auto' : 'manual');
    const [error, setError] = useState(!(autoBillingAvailable && subscription?.status === 'active')
        ? ['Your current plan and payment method must be up to date in order to activate automatic member limit changes.']
        : willCancelAtPeriodEnd
            ? ['Your subscription is set to cancel at the end of the billing period. Automatic member limit changes are not available.']
            : null
    );
    const [buttonState, setButtonState] = useState({...buttonStates.default});
    const [canHaveAutoBilling] = useState(autoBillingAvailable && subscription?.status === 'active' && !willCancelAtPeriodEnd && !isGrace);
    const [isLoading, setIsLoading] = useState(false);
    const disabled = isLoading || isGrace;
    const disabledAuto = disabled || !canHaveAutoBilling;

    const {handleToggleAutoBilling, status, error: toggleError} = useToggleAutoBilling(
        subscription,
        user,
        autoPriceId,
        selectedMode
    );

    useEffect(() => {
        if (status === 'succeeded') {
            updateBillingModeText(selectedMode === 'auto' ? 'Automatic - sit back and relax' : 'Manual - you\'re in control');
            setIsLoading(false);
            setModalLoadingState(false);
            setAutoBillingEnabled(selectedMode === 'auto' ? true : false);
            setButtonState({...buttonStates.success});

            Sentry.setTag('autobilling', selectedMode === 'auto' ? true : false);

            onCloseModal({reload: false, delay: 1000});
        } else if (status === 'failed') {
            setModalLoadingState(false);
            setError(toggleError || 'Could not update billing mode. Try again or contact support@ghost.org');

            Sentry.captureException(toggleError, {
                tags: {
                    source: 'billing-mode',
                    shown_to_user: true
                },
                user: {
                    id: user.id,
                    username: user.stripe_token
                }
            });

            setIsLoading(false);
            setButtonState({...buttonStates.fail});
        }
    }, [status, toggleError]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsLoading(true);
        setButtonState({...buttonStates.pending});
        setModalLoadingState(true);

        if (
            (autoBillingEnabled && selectedMode === 'auto')
            || (!autoBillingEnabled && selectedMode === 'manual')
        ) {
            setModalLoadingState(false);
            setButtonState({...buttonStates.success});
            return onCloseModal({reload: false, delay: 1000});
        }

        await handleToggleAutoBilling(e);
    };

    const handleInputChange = (event) => {
        setSelectedMode(event.target.value);
        setError(null);
        setButtonState({...buttonStates.default});
    };

    return (
        <form className="gh-form billing-mode" onSubmit={handleSubmit} data-testid="billing-mode-form" >
            <header className="billing-mode-header">
                <h1>Member limit changes</h1>
                <p>Choose how you prefer plan updates to work as your audience grows.</p>
            </header>

            <div className="gh-input-group">
                <label style={canHaveAutoBilling ? null : {opacity: 0.5}}>
                    <input
                        className="radio-btn"
                        type="radio"
                        id="auto"
                        name="billing-mode"
                        value="auto"
                        data-testid="auto"
                        disabled={disabledAuto}
                        checked={selectedMode === 'auto'}
                        onChange={handleInputChange}
                    />
                    Automatic - sit back and relax
                    <span>Your plan updates automatically to the correct price as you add or remove members, billing on autopilot.</span>
                </label>

                <label style={disabled ? {opacity: 0.5} : null}>
                    <input
                        className="radio-btn"
                        type="radio"
                        id="manual"
                        name="billing-mode"
                        value="manual"
                        data-testid="manual"
                        disabled={disabled}
                        checked={selectedMode === 'manual'}
                        onChange={handleInputChange}
                    />
                    Manual - you're in control
                    <span>When you exceed your member limits, publishing new posts is paused until you manually update your subscription.</span>
                </label>
            </div>

            <SpinnerButton {...buttonState} data-testid="submit-billing-mode" />

            {error &&
                <div className="form-error" data-testid="billing-mode-error">
                    <p className="error-msg">{error}</p>
                </div>
            }
        </form>
    );
}

BillingMode.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
    user: PropTypes.shape({
        id: PropTypes.string.isRequired,
        stripe_token: PropTypes.string
    }).isRequired,
    subscription: PropTypes.shape({
        id: PropTypes.string.isRequired,
        customer_id: PropTypes.string,
        status: PropTypes.string.isRequired,
        meta: PropTypes.shape({
            baseProduct: PropTypes.shape({
                stripe_price_id: PropTypes.string.isRequired
            }).isRequired,
            addons: PropTypes.arrayOf(PropTypes.shape({
                name: PropTypes.string.isRequired,
                stripe_price_id: PropTypes.string.isRequired
            }))
        }).isRequired
    }).isRequired,
    autoPriceId: PropTypes.string.isRequired,
    updateBillingModeText: PropTypes.func.isRequired,
    autoBillingAvailable: PropTypes.bool.isRequired,
    setModalLoadingState: PropTypes.func.isRequired,
    autoBillingEnabled: PropTypes.bool.isRequired,
    setAutoBillingEnabled: PropTypes.func.isRequired,
    willCancelAtPeriodEnd: PropTypes.bool,
    isGrace: PropTypes.bool.isRequired
};
