import {useState, useCallback} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import {getStripePortalUrl, getPartialCachedBillingData} from '../data/api';

const DEFAULT_PORTAL_URL = 'https://billing.stripe.com/p/login/aEU9COeOC1Ej51K5kk';

export function useStripePortalUrl({customerId}) {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const openPortal = useCallback(async () => {
        try {
            setIsLoading(true);
            setError(null);

            const currentSite = await getPartialCachedBillingData('currentSite');
            const redirectUrl = currentSite?.url ? `${currentSite.url}/ghost/#/pro/` : null;

            // Open a new window immediately upon user interaction
            const newWindow = window.open('', '_blank');

            const res = await getStripePortalUrl({customerId, redirectUrl});

            if (res.error) {
                const loggingError = new Error('Failed to receive Stripe portal URL');

                Sentry.captureException(loggingError, {
                    tags: {
                        source: 'stripe-portal-button',
                        shown_to_user: true
                    },
                    contexts: {
                        full_error: res?.error?.originalError || res?.error
                    },
                    user: {
                        username: customerId
                    }
                });

                newWindow.location.href = DEFAULT_PORTAL_URL;
            } else if (res.url) {
                if (newWindow && !newWindow.closed) {
                    newWindow.location.href = res.url;
                } else {
                    // Fallback if window was closed or blocked
                    window.open(res.url, '_blank');
                }
            }

            return true;
        } catch (err) {
            setError(err.message || 'Failed to get Stripe portal URL');
            return false;
        } finally {
            setIsLoading(false);
        }
    }, [customerId]);

    return {
        openPortal,
        isLoading,
        error
    };
}

useStripePortalUrl.propTypes = {
    customerId: PropTypes.string.isRequired
};
