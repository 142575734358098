import React from 'react';
import PropTypes from 'prop-types';
import maxBy from 'lodash/maxBy';
import {findSuitablePrices} from '../../utils/member-limits';
import productUtils from '../../utils/ProductUtils';

function Feature({feature}) {
    return (
        <li>{feature}</li>
    );
}

// Render the features string and include the max. possible members amount
function Features({features}) {
    return (
        features.map((feature, index) => (<Feature key={index} feature={feature} />))
    );
}

export default function Product({
    productName,
    handlePlanSelected,
    showPeriod,
    productGroup,
    addons,
    isSubdirectory,
    prices,
    currentMembers,
    currentSiteLimits,
    limits,
    currentPrice,
    currentGhostVersion,
    features,
    hasCustomSendingDomain,
    availableProducts
}) {
    const _verbose = (process.env.REACT_APP_VERBOSE === 'true') || false;

    // TODO: Move all of this logic into Business logic 😱

    // Find available subdirectory addons for the product. A user with a currently
    // setup subdirectory can only change to a different product
    // that supports subdirectory addons.
    const availableAddonPrices = addons?.length >= 1
        ? productUtils.processAddons({addons, isSubdirectory, showPeriod})
        : [];

    const suitablePrices = findSuitablePrices(prices, currentMembers, showPeriod);
    const {suitablePrice = null, limitsValidations = []} = productUtils.getPriceAndLimits({suitablePrices, currentSiteLimits, productName, limits, _verbose});

    // This is to render the max. possible members number for the same price range
    const sameCostPrices = suitablePrices.filter(price => price.cost === suitablePrice?.cost);
    const maxMembersForPrice = maxBy(sameCostPrices, price => price.limits[0].value);

    const isLastStepAndNotEntryProduct = productUtils.isLastStepAndNotEntryProduct({currentMembers, productName, availableProducts});

    const {disableProduct: btnDisabled, validationMsg} = productUtils.shouldDisableProduct({
        suitablePrice,
        currentPrice,
        prices,
        showPeriod,
        isSubdirectory,
        currentGhostVersion,
        productName,
        limitsValidations,
        availableAddonPrices,
        hasCustomSendingDomain,
        availableProducts
    });

    const cost = productUtils.calculateTotalCost({suitablePrice, showPeriod});

    const {productFeatures, addonFeatures} = productUtils.getFeatures({
        suitablePrice,
        maxMembersForPrice,
        features,
        isLastStepAndNotEntryProduct,
        productName,
        prices,
        showPeriod,
        availableAddonPrices,
        isSubdirectory,
        availableProducts
    });

    const isLastIncrementStep = productUtils.isLastIncrementStep({currentMembers, availableProducts});
    // Also disable any product that doesn't suit the selected members
    const shouldDisablePlan = !suitablePrice || btnDisabled;
    const disabledClass = (shouldDisablePlan) ? ' plan-disabled' : '';

    const handleSelect = (product) => {
        handlePlanSelected(product, productGroup, showPeriod);
    };

    return (
        <>
            {isLastStepAndNotEntryProduct ?
                <section>
                    <header>
                        <h3>{productName}</h3>
                        <div className="price-more">
                            <p>Over <strong>400,000</strong> members?</p>
                            <p><a href="mailto:concierge@ghost.org">Contact us</a> to talk to our team</p>
                        </div>
                    </header>
                    <ul className="plan-features">
                        {productFeatures?.length > 0 &&
                            <Features features={productFeatures} />
                        }
                    </ul>
                    {addonFeatures?.length > 0 &&
                        <ul className="plan-addons">
                            <Features features={addonFeatures} />
                        </ul>
                    }
                </section>
                :
                <section className={`${disabledClass}`}>
                    <header>
                        <h3>{productName}</h3>
                        <div className="price">
                            <div className="price-sym">$</div>
                            <div className="price-amount">{cost}</div>
                            <div className="price-term">/mo</div>
                        </div>
                        {validationMsg &&
                                <div className="price-unavailable">
                                    <p>{validationMsg}</p>
                                </div>
                        }
                        {!isLastIncrementStep && !validationMsg &&
                            <button
                                className="gh-btn gh-btn-block"
                                data-test-btn={productName}
                                disabled={btnDisabled}
                                onClick={() => handleSelect(productName?.toLowerCase())}
                            >
                                Choose this plan
                            </button>
                        }
                    </header>
                    <ul className="plan-features">
                        {productFeatures?.length > 0 &&
                            <Features features={productFeatures} />
                        }
                    </ul>
                    {addonFeatures?.length > 0 &&
                        <ul className="plan-addons">
                            <Features features={addonFeatures} />
                        </ul>
                    }
                </section>
            }
        </>

    );
}

Product.propTypes = {
    name: PropTypes.string,
    features: PropTypes.array,
    prices: PropTypes.array,
    showPeriod: PropTypes.string,
    productGroup: PropTypes.string,
    handlePlanSelected: PropTypes.func,
    currentPrice: PropTypes.object,
    currentSiteLimits: PropTypes.object,
    isSubdirectory: PropTypes.bool,
    disabled: PropTypes.bool,
    currentGhostVersion: PropTypes.object,
    limits: PropTypes.array,
    hasCustomSendingDomain: PropTypes.bool,
    availableProducts: PropTypes.array
};
