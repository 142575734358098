import React from 'react';
import PropTypes from 'prop-types';
import SpinnerButton from '../../shared/SpinnerButton';
import {useStripePortalUrl} from '../../../hooks/useStripePortalUrl';
/**
 * @param {{customerId: string}} props
 * @returns {JSX.Element}
 */
export default function StripePortalButton({
    customerId
}) {
    const {openPortal, isLoading} = useStripePortalUrl({customerId});

    return (
        <div className="stripe-portal-button">
            <SpinnerButton
                className={`gh-btn gh-btn-block ${isLoading ? 'spinner' : ''}`}
                html={
                    isLoading
                        ? '<span><div class="gh-spinner"></div></span>'
                        : 'View &rarr;'
                }
                onClick={openPortal}
                disabled={isLoading}
            />
        </div>
    );
}

StripePortalButton.propTypes = {
    customerId: PropTypes.string.isRequired
};
