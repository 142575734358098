import {getBillingData} from './api';

class ParentWindowDataChannel {
    constructor() {
        this.subscribers = [];

        window.addEventListener('message', this.handleIncomingMessage.bind(this));
    }

    subscribe(queryName, cb) {
        this.subscribers[queryName] = cb;
    }

    handleIncomingMessage(event) {
        if (event && event.data) {
            let subscriber = this.subscribers[event.data.query];

            if (subscriber) {
                let responseHandler = () => {};

                if (event.data.query === 'getSubscription' && !event.data.response) {
                    event.data.response = 'subscription';
                }

                // Ghost Admin can send us route updates to enforce a redirect, as
                // child routes can't be set from within the Admin.
                if (event.data.query === 'routeUpdate' && event.data.response) {
                    return subscriber(event.data.response);
                }

                if (event.data.response) {
                    responseHandler = (data) => {
                        let response = {};
                        response[event.data.response] = data;

                        this.sendMessage(response);
                    };
                }

                subscriber(responseHandler);
            }
        }
    }

    sendMessage(data) {
        if (data?.error) {
            // don't try sending errors to the Ghost Admin
            return;
        }

        window.parent.postMessage(data, '*');
    }

    async getToken() {
        const sendMessage = this.sendMessage;

        return new Promise(function (resolve) {
            window.addEventListener('message', function self(event) {
                if (event.data && event.data.request === 'token') {
                    window.removeEventListener('message', self);

                    resolve(event.data.response);
                }
            });

            sendMessage({request: 'token'});
        });
    }

    async getForceUpgradeInfo() {
        // Create an AbortController which allows us to cancel
        // the eventListener and avoids waiting forever for the
        // message like some princesses wait for their prince.
        const controller = new AbortController();
        const sendMessage = this.sendMessage;

        return new Promise(function (resolve) {
            window.addEventListener('message', function self(event) {
                if (event.data && event.data.request === 'forceUpgradeInfo') {
                    window.removeEventListener('message', self);

                    resolve(event.data.response);
                }
            }, {signal: controller.signal});

            sendMessage({request: 'forceUpgradeInfo'});

            setTimeout(() => {
                controller.abort();
                resolve({});
            }, 2000);
        });
    }

    async sendSubscriptionData(billingData) {
        if (!billingData) {
            billingData = await getBillingData();
        }

        if (billingData?.error) {
            // don't try sending errors to the Ghost Admin
            return;
        }

        this.sendMessage(billingData);
    }
}

const parentWindowDataChannelSingleton = new ParentWindowDataChannel();

export default parentWindowDataChannelSingleton;
