import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';
import {getCachedBillingData, getPartialBillingData} from '../data/api';
import {differenceInDays, fromUnixTime, formatDistanceToNowStrict} from 'date-fns';
import * as Sentry from '@sentry/react';
import Product from './Plans/Product';
import CancelModal from './shared/CancelModal';
import formatNumber from '../utils/format-number';
import {
    getCouponDiscount,
    getPurchasableProducts
} from '../utils/billing-utils';

function ExternalLinkIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12" height="12" width="12">
            <g transform="matrix(0.5,0,0,0.5,0,0)">
                <line style={{fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px'}} x1="9.25" y1="14.523" x2="23.25" y2="0.75"></line>
                <polyline style={{fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px'}} points="23.25 8.621 23.25 0.75 15.25 0.75"></polyline>
                <path style={{fill: 'none', stroke: '#000000', strokeLinecap: 'round', strokeLinejoin: 'round', strokeWidth: '1.5px'}} d="M12.125,5.75H1.625a.875.875,0,0,0-.875.875v15.75a.875.875,0,0,0,.875.875h15.75a.875.875,0,0,0,.875-.875v-10.5"></path>
            </g>
        </svg>
    );
}

let _isMounted = false;

/**
 * @typedef {object} StripeCoupon
 * @property {string} id
 * @property {string} object
 * @property {number} amount_off
 * @property {number} created
 * @property {string} currency // only if amount_off has value
 * @property {'forever'|'once'|'repeating'} duration
 * @property {number} duration_in_months // only if duration is repeating
 * @property {boolean} livemode
 * @property {number} max_redemptions
 * @property {object} metadata
 * @property {string} name
 * @property {number} percent_off
 * @property {string} redeem_by
 * @property {number} times_redeemed
 * @property {boolean} valid
 */

/**
 * @typedef {object} StripeDiscount
 * @property {StripeCoupon} coupon
 */

/**
 *
 * @param {{discount: StripeDiscount, isTrial: boolean}} props
 * @returns
 */
function CouponBanner({discount, isTrial = false}) {
    let durationText;
    let freeMonths = 'month';
    const endDate = discount?.end ? new Date(fromUnixTime(discount.end)) : null;

    if (endDate) {
        freeMonths = formatDistanceToNowStrict(endDate, {addSuffix: false});
    }
    // Do a primitive validation if the coupon ID is conform
    // with our naming convention. See https://www.notion.so/ghost/How-to-create-marketing-offers-420239887ba44b229476a94588c0f48c?pvs=4
    const hasReadableID = discount.coupon.id.match(/^[A-Z\d]*(?:[\d]*$)/);
    const discountText = getCouponDiscount({coupon: discount.coupon, calculateTotal: false, format: true});

    switch (discount.coupon.duration) {
    case 'once':
        durationText = isTrial ? 'your first payment' : 'your next payment';
        break;
    case 'forever':
        durationText = 'forever';
        break;
    case 'repeating':
        durationText = `for the next ${freeMonths}`;
        break;
    default:
        break;
    }

    return (
        <>
            {hasReadableID ?
                <div className="coupon">
                Coupon: {discount.coupon.id.toUpperCase()} has been applied to your account. You'll get {discountText} off {durationText}.
                </div>
                :
                <div className="coupon">
                    A coupon has been applied to your account. You'll get {discountText} off {durationText}.
                </div>
            }
        </>
    );
}
export function Plans({
    showNav,
    updateTitle,
    history
}) {
    const [loading, setLoading] = useState(true);
    const [showAnnual, setShowAnnual] = useState(true);
    // Set members to 500 when there are none yet
    const [closeModal, setCloseModal] = useState(false);
    const [billingData, setBillingData] = useState(null);
    const [products, setProducts] = useState(null);
    const [currentMembers, setCurrentMembers] = useState(null);
    const [currentSiteLimits, setCurrentSiteLimits] = useState(null);

    useEffect(() => {
        if (loading) {
            showNav(false);
        }

        if (!_isMounted) {
            _isMounted = true;

            const fetchCachedBillingData = async () => {
                const cachedBillingData = await getCachedBillingData();

                const isCustomPlan = cachedBillingData?.currentPrice?.base_product?.toLowerCase().indexOf('custom') >= 0;
                const hasErrorOrNoBillingData = !cachedBillingData || cachedBillingData?.error;
                const hasNoAvailableProducts = !cachedBillingData?.availableProducts;
                const isForceUpgrade = cachedBillingData?.forceUpgradeOwner;
                const isDunningAndNotForceUpgrade = cachedBillingData?.isGrace && !isForceUpgrade;

                const willCancelAtPeriodEnd = cachedBillingData?.willCancelAtPeriodEnd;
                const redirectWhenNotForceUpgrade = (hasErrorOrNoBillingData || hasNoAvailableProducts) && !isForceUpgrade;

                const shouldRedirectToCheckout = cachedBillingData?.isIncomplete || cachedBillingData?.isAutoBillingGrace;
                const shouldRedirectToDashboard = willCancelAtPeriodEnd
                    || isCustomPlan
                    || isDunningAndNotForceUpgrade
                    || redirectWhenNotForceUpgrade;

                Sentry.setTag('source', 'plans');

                Sentry.setExtra('siteId', cachedBillingData?.currentSite?.id);

                setBillingData(cachedBillingData);

                if (shouldRedirectToCheckout) {
                    // Redirect back to Checkout with the current plan to finish the payment
                    return history.push(cachedBillingData?.checkoutRoute || '/plans');
                }

                if (shouldRedirectToDashboard) {
                    if (isDunningAndNotForceUpgrade) {
                        // TODO: why are we showing the nav here already?
                        showNav(true);
                    }

                    // Redirect back to Dashboard
                    return history.push('/');
                }

                // Ensure we always load fresh limit data from Daisy in case anything has changed (e. g. theme or number of staff)
                const cachedCurrentSiteLimits = await getPartialBillingData('currentSiteLimits');

                let membersLimit = 500;

                if (cachedBillingData?.currentSiteLimits?.members?.total) {
                    membersLimit = cachedBillingData?.currentSiteLimits?.members?.total === 0
                        ? membersLimit
                        : cachedBillingData?.currentSiteLimits?.members?.total;
                }

                const sortedProducts = getPurchasableProducts(cachedBillingData?.availableProducts, cachedBillingData?.user?.show_product_group);

                if (!sortedProducts || !sortedProducts.length) {
                    Sentry.withScope((scope) => {
                        scope.setTag('source', 'getPurchasableProducts');
                        scope.setExtras({
                            siteId: cachedBillingData?.currentSite?.id,
                            product_group: cachedBillingData?.user?.show_product_group
                        });
                        Sentry.captureMessage('No purchasable products found for user in `/plans`', 'warning');
                    });

                    if (!isForceUpgrade && !cachedBillingData?.isTrial) {
                        return history.push('/');
                    }
                }

                setCurrentSiteLimits(cachedCurrentSiteLimits);
                setProducts(sortedProducts);
                setCurrentMembers(membersLimit);
                setLoading(false);
                showNav(true);
            };

            fetchCachedBillingData();
        }
        return () => {
            _isMounted = false;
        };
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        const updateHeaderTitle = async ({
            isTrial,
            trialEnd,
            isForceUpgrade,
            lastSubscriptionType
        }) => {
            if (isTrial && trialEnd) {
                const trialEndDate = new Date(trialEnd);
                const trialEndInDays = differenceInDays(trialEndDate, new Date());
                let trialWording = '';

                switch (trialEndInDays) {
                case 0:
                    trialWording = '<strong>today</strong>';
                    break;
                case 1:
                    trialWording = `in <strong>${trialEndInDays} day</strong>`;
                    break;
                default:
                    trialWording = `in <strong>${trialEndInDays} days</strong>`;
                    break;
                }

                updateTitle(`Your trial ends ${trialWording}. You can pick a plan now to start your subscription and unlock the full features of Ghost(Pro) right away.`);
            } else if (isForceUpgrade && lastSubscriptionType === 'trial') {
                updateTitle('Your free trial is over! Pick a plan now, and unlock the full features of Ghost(Pro)');
            } else if (!isTrial) {
                updateTitle('Select a new plan to fit your needs.');
            } else {
                updateTitle('Pick a plan now to start a subscription and keep your work online.');
            }
        };
        if (_isMounted) {
            updateHeaderTitle({
                isTrial: billingData?.isTrial,
                trialEnd: billingData?.subscription?.trial_end,
                isForceUpgrade: billingData?.isForceUpgrade,
                lastSubscriptionType: billingData?.lastSubscriptionType
            });
        }
        return () => {
            updateHeaderTitle('Billing and domain settings');
        };
    }, [
        billingData?.isTrial,
        updateTitle,
        billingData?.subscription?.trial_end,
        billingData?.isForceUpgrade,
        billingData?.lastSubscriptionType
    ]);

    const handleNestedLoadingState = (value) => {
        setLoading(value);
        showNav(!value);
    };

    const handleToggleAnnual = (event) => {
        setShowAnnual(event.target.checked);
    };

    const handlePlanSelected = (planId, group, period) => {
        group = group ?? billingData?.user?.show_product_group;
        period = period ?? (showAnnual ? 'year' : 'month');

        return history.push(`/checkout/${planId}-${group}-${period}`);
    };

    const handleOpenModal = (value) => {
        setCloseModal(value);
    };

    const handleCloseModal = () => {
        setCloseModal(false);
    };

    return (
        <main className="main main-wide">

            {loading ?
                (
                    <div className="gh-loading-content">
                        <div
                            className="gh-loading-spinner"
                            data-testid="gh-loading-spinner"
                        ></div>
                    </div>
                ) :
                (
                    <>
                        {billingData?.discount?.coupon && billingData?.user?.coupon &&
                            <CouponBanner
                                discount={billingData?.discount}
                                isTrial={billingData?.isTrial}
                            />
                        }
                        <div
                            className={products?.length === 3 ? 'planselect plan-3' : 'planselect'}
                            data-testid="plans"
                        >
                            <section className="plancalc">
                                <header>
                                    {currentMembers === 0 ?
                                        <h3>Including an audience <br></br>of up to</h3>
                                        :
                                        <h3>Based on your total <br></br>audience size of</h3>
                                    }
                                    <div className="plancalc-memberscount">
                                        <div className="plancalc-memberscount-number">
                                            {formatNumber(currentMembers)}
                                        </div>
                                        <div className="plancalc-memberscount-label">Members</div>

                                    </div>

                                    <a href="https://ghost.org/pricing/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">
                                            View detailed pricing table
                                        <span className="link-ext">
                                            <ExternalLinkIcon />
                                        </span>
                                    </a>

                                    <p>Members are people who have signed up to your site. They can log in, receive email newsletters, or buy paid subscriptions.</p>
                                </header>
                                <footer>
                                    <div>
                                        <strong>Annual discount</strong>
                                    </div>
                                    <span>
                                        <input
                                            id="interval"
                                            className="toggle-btn"
                                            type="checkbox"
                                            name="interval"
                                            defaultChecked
                                            onChange={handleToggleAnnual}
                                        />
                                        <label className="hidden" htmlFor="interval"></label>
                                    </span>
                                </footer>
                            </section>

                            {products
                                .map(product => (
                                    <Product
                                        productName={product?.name}
                                        addons={product?.addons}
                                        prices={product?.prices}
                                        limits={product?.limits}
                                        features={product?.features}
                                        currentMembers={currentMembers}
                                        handlePlanSelected={handlePlanSelected}
                                        key={product?.name}
                                        showPeriod={showAnnual ? 'year' : 'month'}
                                        productGroup={product?.group}
                                        isSubdirectory={billingData?.currentSite?.subdirectory
                                            ? true
                                            : false}
                                        hasCustomSendingDomain={billingData?.currentSite?.sending_domain_status === 'VERIFIED'}
                                        currentPrice={billingData?.currentPrice}
                                        currentGhostVersion={billingData?.currentGhostVersion}
                                        currentSiteLimits={currentSiteLimits}
                                        availableProducts={products}
                                    />
                                )
                                )}
                        </div>

                        <footer className={`planselector-footer ${billingData?.isTrial ? 'two-columns' : ''}`}>
                            <div className="footer-cancel">

                                {billingData?.isTrial &&
                                        <CancelModal
                                            subscription={billingData?.subscription}
                                            setLoadingState={handleNestedLoadingState}
                                            onCloseModal={handleCloseModal}
                                            setModalIsOpen={handleOpenModal}
                                            modalIsOpen={closeModal}
                                            billingData={billingData}
                                        />
                                }

                            </div>

                            <div className="footer-compare">
                                <a href="https://ghost.org/pricing/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer">View full pricing page &rarr;</a>
                            </div>

                        </footer>
                    </>
                )
            }
        </main>
    );
}

Plans.propTypes = {
    showNav: PropTypes.func,
    updateTitle: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }).isRequired
};

export default withRouter(Plans);
