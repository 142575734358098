import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {
    Link,
    withRouter
} from 'react-router-dom';
import {
    getPartialBillingData,
    getCachedBillingData,
    checkSendingDomain,
    verifySendingDomain,
    removeSendingDomain
} from '../../data/api';
import * as Sentry from '@sentry/react';

import SpinnerButton from '../shared/SpinnerButton';
import DNSTable from './DNSTable';
import DomainStatusField from './DomainStatusField';

let _isMounted = false;

function CustomSendingDomain({
    updateTitle,
    history,
    showNav
}) {
    // We should have four states of a submit button
    // remove when input value is empty -> shouldn't be allowed (black button disabled)
    // 1. update when new domain is different to old domain (black button)
    // 2. activate when new domain is entered and valid and no old domain exists (black button)
    // 3. try again when domain setup failed for whatever reason (red button)
    // 4. continue (which also fetches fresh subscription data) after a successful activation (green button)
    const buttonStates = {
        fail: {
            className: 'gh-btn gh-btn-block',
            html: 'Try again',
            onSubmit: 'handleSubmit',
            disabled: false
        },
        pending: {
            className: 'gh-btn gh-btn-block spinner',
            html: '<span><div class="gh-spinner"></div>Activation may take up to 1 day</span>',
            onBack: 'reloadAndBack',
            disabled: true
        },
        default: {
            className: 'gh-btn gh-btn-block',
            html: 'Activate',
            onSubmit: 'handleSubmit',
            disabled: false
        }
    };

    const [loading, setLoading] = useState(true);
    const [buttonState, setButtonState] = useState(buttonStates.default);
    const [error, setError] = useState(null);
    const [currentSite, setCurrentSite] = useState(null);
    const [showError, setShowError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [dnsRecords, setDnsRecords] = useState([]);
    const [showRemoveDomain, setShowRemoveDomain] = useState(false);
    const [showDomainStatusField, setShowDomainStatusField] = useState(false);

    useEffect(() => {
        if (showNav && loading) {
            showNav(false);
        }
    }, [showNav, loading]);

    const reloadAndBack = async (event) => {
        event.preventDefault();

        setButtonState(buttonStates.default);
        setLoading(true);

        showNav(false);

        const updatedSite = await getPartialBillingData('currentSite');

        setCurrentSite(updatedSite);
        setLoading(false);

        showNav(true);

        return history.push('/domain');
    };

    const handleRemoveDomain = async (event) => {
        event.preventDefault();

        setLoading(true);
        setError(null);

        try {
            const res = await removeSendingDomain({siteId: currentSite?.id});

            if (res?.error) {
                const message = res.error?.message || 'Unable to remove custom sending domain';

                setLoading(false);
                setErrorMessage(message);
                return;
            }

            setShowRemoveDomain(false);

            return reloadAndBack(event);
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    source: 'handleRemoveDomain',
                    shown_to_user: true
                },
                extra: {
                    siteId: currentSite?.id
                },
                user: {
                    id: currentSite?.user_id
                }
            });

            setLoading(false);
            setErrorMessage(err?.message || 'Unable to remove custom sending domain');
            return;
        }
    };

    const handleSubmit = async (event) => {
        event?.preventDefault();

        setButtonState(buttonStates.pending);
        setError(null);

        try {
            const res = await verifySendingDomain({
                siteId: currentSite?.id
            });

            if (res?.error) {
                const message = res.error?.message || 'Unable to verify custom domain';

                Sentry.withScope((scope) => {
                    scope.setTags({
                        source: 'handleSubmit',
                        shown_to_user: true
                    });
                    scope.setContext('full_error', res?.error);
                    scope.setExtra('siteId', currentSite?.id);
                    Sentry.captureMessage(message, 'warning');
                });

                setError(message);
                setButtonState(buttonStates.fail);
                return;
            }

            // allow sending domain to be removed now
            setShowRemoveDomain(true);

            if (res && res.mailgun_dns_records && res.dmarc_dns_record) {
                const records = [...res.mailgun_dns_records];

                if (res.dmarc_dns_record.valid === 'unknown') {
                    records.push(res.dmarc_dns_record);
                }
                setDnsRecords(records);
            }

            setCurrentSite({
                ...currentSite,
                sending_domain_status: res?.domain_status
            });

            if (res?.active || res?.domain_status === 'VERIFIED') {
                setShowDomainStatusField(true);
                return;
            }

            setShowDomainStatusField(false);
            setButtonState(buttonStates.pending);
            return;
        } catch (err) {
            Sentry.captureException(err, {
                tags: {
                    source: 'handleSubmit',
                    shown_to_user: true
                },
                extra: {
                    siteId: currentSite?.id
                },
                user: {
                    id: currentSite?.user_id
                }
            });
            setError(err?.message || 'Unable to verify custom domain');
            setButtonState(buttonStates.fail);
            return;
        }
    };

    const contactSupport = (event) => {
        event.preventDefault();
        return window.location.href = 'mailto:support@ghost.org';
    };

    const setSpinnerButtonAction = (event) => {
        if (buttonState?.disabled || !buttonState?.onSubmit) {
            return null;
        } else if (buttonState?.onSubmit === 'handleSubmit') {
            return handleSubmit(event);
        }
    };

    useEffect(() => {
        if (!_isMounted) {
            _isMounted = true;

            const fetchCachedBillingData = async () => {
                const billingData = await getCachedBillingData();
                const {isGrace, forceUpgradeOwner} = billingData;
                const site = await getPartialBillingData('currentSite');

                Sentry.setTags({
                    source: 'custom-sending-domain'
                });
                Sentry.setExtra('siteId', currentSite?.id);
                Sentry.setExtra('site', currentSite?.external_domain);

                if (!billingData || billingData?.error) {
                    if (billingData?.error?.status === 403) {
                        setShowError(true);
                        setErrorMessage(<>You’re on a special legacy plan, if you need to make any billing changes reach out to us on <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a></>);
                    } else {
                        return history.push('/');
                    }
                }

                // Site with a lapsed trial or subscription are in a `forceUpgrade` state.
                // If the current user is the owner of the site, redirect them to the
                // plan selection to checkout a new subscription.
                if (forceUpgradeOwner || isGrace) {
                    showNav(true);
                    // Return to Dashboard when user is in dunning to update card details
                    return history.push(forceUpgradeOwner ? '/plans' : '/');
                }

                // Ensure a sending domain is already initialized or it's a custom setup
                if (site?.sending_domain_status === 'NONE' || site?.sending_domain_status === 'CUSTOM' || !site?.sending_domain_status) {
                    return history.push('/domain');
                }

                // Check if the state has been passed from the domain page
                if (history?.location?.state && history.location.state.length !== 0) {
                    const records = history.location.state;

                    setDnsRecords(records);
                } else {
                    // and if not, fetch the current sending domain status
                    const checkedSendingDomain = await checkSendingDomain({siteId: site?.id});

                    site.sending_domain_status = checkedSendingDomain?.domain_status;

                    if (checkedSendingDomain?.error) {
                        Sentry.withScope((scope) => {
                            scope.setTags({
                                source: 'checkSendingDomain',
                                shown_to_user: true
                            });
                            scope.setContext('full_error', checkedSendingDomain?.error);
                            scope.setExtra('siteId', currentSite?.id);
                            Sentry.captureMessage(checkedSendingDomain?.error?.message, 'warning');
                        });

                        setShowError(true);
                        setErrorMessage(<>There’s an issue with your custom sending domain, reach out to us on <a href="mailto:support@ghost.org" onClick={contactSupport}>support@ghost.org</a></>);
                    }

                    if (checkedSendingDomain?.mailgun_dns_records && checkedSendingDomain?.dmarc_dns_record) {
                        const records = [...checkedSendingDomain.mailgun_dns_records];

                        if (checkedSendingDomain?.dmarc_dns_record?.valid === 'unknown') {
                            records.push(checkedSendingDomain?.dmarc_dns_record);
                        }

                        setDnsRecords(records);
                    }
                }

                if (site?.sending_domain_status === 'VERIFIED') {
                    setShowDomainStatusField(true);
                    setShowRemoveDomain(true);
                } else if (site?.sending_domain_status === 'PENDING') {
                    setShowDomainStatusField(false);
                    setButtonState(buttonStates.pending);
                    setShowRemoveDomain(true);
                } else {
                    setShowDomainStatusField(false);
                    setShowRemoveDomain(false);
                    setButtonState(buttonStates.default);
                }

                setCurrentSite(site);
                setLoading(false);
                showNav(false);
            };

            updateTitle('Custom sending domain settings');
            fetchCachedBillingData();
        }

        return () => {
            _isMounted = false;
        };
    }, []); // eslint-disable-line

    useEffect(() => {
        const handleEnterKey = (e) => {
            if (e.code === 'Enter') {
                if (currentSite?.sending_domain_status === 'PENDING') {
                    return handleSubmit(e);
                }
            }
        };

        window.addEventListener('keydown', handleEnterKey);

        return () => {
            window.removeEventListener('keydown');
        };
    }, []); // eslint-disable-line

    return (
        <main className="main">

            <div className="domain" data-test-id="domain">

                {loading ?
                    (
                        <div className="gh-loading-content">
                            <div className="gh-loading-spinner"></div>
                        </div>
                    ) :
                    (showError) ?
                        (
                            <div className="domaingrid">
                                <header className="domain-nav">
                                    <Link to="/domain" className="link-back" onClick={reloadAndBack}>&larr; Back</Link>
                                </header>
                                <p style={{textAlign: 'center', gridColumn: '1 / 3'}}>{errorMessage}</p>
                            </div>
                        ) :
                        (
                            <div className="domaingrid">
                                <header className="domain-nav">
                                    <Link to="/domain" className="link-back" onClick={currentSite?.sending_domain_status === 'INITIALIZED' ? handleRemoveDomain : reloadAndBack}>&larr; Back</Link>
                                    <div className="actions">
                                        <a className="gh-btn gh-btn-green gh-btn-sm" href="https://ghost.org/help/custom-sending-domains/?ref=billing.ghost.org" target="_blank" rel="noopener noreferrer" data-test-btn="sending-domain-help">Get help</a>
                                    </div>
                                </header>

                                {Object.keys(dnsRecords)?.length > 1 &&
                                <>
                                    <div className="box-label">1. Add these DNS records to {currentSite?.external_domain}</div>

                                    <DNSTable
                                        dnsRecords={dnsRecords}
                                        showStatus={true}
                                        boxClass="dns-form"
                                    />

                                    <div className="box-label">2. Activate custom sending domain</div>
                                    <section id="step-3" className='domain-form step-activate'>
                                        {!showDomainStatusField
                                            ?
                                            <SpinnerButton
                                                onClick={event => setSpinnerButtonAction(event)}
                                                className={buttonState.className}
                                                html={buttonState.html}
                                                disabled={buttonState.disabled}
                                                loading={buttonState.loading}
                                                data-test-btn="activate-sending-domain"
                                            />
                                            :
                                            <DomainStatusField
                                                status={currentSite?.sending_domain_status}
                                                domain={currentSite?.external_domain}
                                                type="custom-sending-domain"
                                            />
                                        }

                                        {error &&
                                            <div className="form-error">
                                                <p className="error-msg">{error}</p>
                                            </div>
                                        }

                                        {showRemoveDomain ?
                                            <button className="domain-remove" data-test-btn="remove-sending-domain" onClick={handleRemoveDomain}>
                                                {currentSite?.sending_domain_status === 'VERIFIED' ? 'Remove sending domain' : 'Cancel & remove sending domain'}
                                            </button>
                                            :
                                            <p className="domain-deliverability">
                                                <strong>Note:</strong> You may see lower deliverability for a few days while your sending domain warms up.&nbsp;
                                                <a href="https://ghost.org/help/custom-sending-domains#deliverability-for-new-custom-sending-domains" target="_blank" rel="noopener noreferrer" data-test-btn="sending-domain-deliverability">Learn more &rarr;</a>
                                            </p>
                                        }
                                    </section>
                                </>
                                }
                            </div>
                        )
                }

            </div>

        </main>
    );
}

CustomSendingDomain.propTypes = {
    updateTitle: PropTypes.func,
    history: PropTypes.shape({
        push: PropTypes.func.isRequired
    }),
    showNav: PropTypes.func
};

export default withRouter(CustomSendingDomain);
